import React from 'react'
import { Link, useNavigate, NavigateFunction } from 'react-router-dom'

import { IMenuLinks } from '../../core/enums'

import './Header.scss'
import { IMG_LeftArrow, IMG_Search } from '../../assets/img'

type IComponentProps = {
    home?: boolean,
    title?: string,
    subtitle?: string,
    onBack?: () => any
}

const Header: React.FC<IComponentProps> = ({ home, title, subtitle, onBack }) => {
    const navigate: NavigateFunction = useNavigate()

    const backButtonClick = () => {
        onBack ? onBack() : navigate({
            pathname: IMenuLinks.Home
        })

        return
    }

    if (home) {
        return (
            <nav className="Header">
                {/* <Link className="search-button" to={IMenuLinks.Linhas}>
                    <div className="icon">
                        <img src={IMG_Search} alt="Buscar Linhas"></img>
                    </div>
                    Buscar Linhas
                </Link> */}
            </nav>
        )
    } else {
        return (
            <nav className="Header">
                <button className="header-button" onClick={backButtonClick}>
                    <div className="icon">
                        <img src={IMG_LeftArrow} alt="Voltar" />
                    </div>
                </button>
                <div className="title">
                    { title && (<h1 className="main-title">{title}</h1>)}
                    { subtitle && (<h2 className="sub-title">{subtitle}</h2>)}
                </div>
                <div>
                    
                </div>
            </nav>
        )
    }
}

export default Header