import React, { MouseEventHandler } from "react"
import { IMG_Logo } from "../../assets/img"
import VideoPlayer from "../VideoPlayer"

import './Card.scss'

type IComponentProps = {
    title?: string,
    image?: string,
    onClick?: MouseEventHandler<HTMLDivElement>
    isProduct?: boolean
}

const Card: React.FC<IComponentProps> = ({
    title = 'Sem Título',
    image = IMG_Logo,
    onClick,
    isProduct = false
}): JSX.Element => {
    return (
        <div className="Card" onClick={onClick}>
            <div className={`${isProduct ? 'video-box' : "card-image"}`}>
                { isProduct ? (
                    <VideoPlayer source={image} controls={false} autoplay muted/>
                ) : (
                    <img src={image} alt={title} />
                )}
            </div>
            <hr />
            <div className="card-title">
                {title}
            </div>
        </div>
    )
}

export default Card