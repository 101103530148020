export const Utils = {
    formatTime: function (seconds: any) {
        let roundedSeconds = Math.trunc(seconds);

        let hours = Math.trunc(roundedSeconds / 3600);

        let stringHours = "";
        let stringMinutes = "00:";
        let stringSeconds = "00";

        if (hours > 0) {
        roundedSeconds -= 3600 * hours;

        if (hours < 10) {
            stringHours = "0" + hours + ":";
        } else {
            stringHours = hours + ":";
        }
        }

        let minutes = Math.trunc(roundedSeconds / 60);

        if (minutes > 0) {
        roundedSeconds -= 60 * minutes;

        if (minutes < 10) {
            stringMinutes = "0" + minutes + ":";
        } else {
            stringMinutes = minutes + ":";
        }
        }

        if (roundedSeconds > 0) {
        if (roundedSeconds < 10) {
            stringSeconds = "0" + roundedSeconds;
        } else {
            stringSeconds = roundedSeconds.toString();
        }
        }

        let stringTime = stringHours + stringMinutes + stringSeconds;

        return stringTime;
    },
    parseRouteParam: function (route: string, param: string, value: string) {
        return route.replace(':' + param, this.slugify(value));
    },
    slugify: function (str: string):string {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
      
        // remove accents, swap ñ for n, etc
        var from = "àáäâãèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
    
        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes
    
        return str;
    }
}