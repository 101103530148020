import React from "react"
import { Routes, Route } from 'react-router-dom'

import { DataProvider } from "../contexts/DataContext"

import MainLayout from "../containers/MainLayout"

import HomePage from "../pages/HomePage"
import ModoFeiraPage from "../pages/ModoFeiraPage"
import VideoInstitucionalPage from "../pages/VideoInstitucionalPage"
import LinhasPage from "../pages/LinhasPage"
import LinhaPage from "../pages/LinhaPage"
import ProdutoPage from "../pages/ProdutoPage"

import { IMenuLinks } from "../core/enums"

type IComponentProps = {
}

const AppRoutes: React.FunctionComponent<IComponentProps> = () => {
    return (
        <DataProvider>
            <Routes>
                <Route path={IMenuLinks.Home} element={<MainLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path={IMenuLinks.Linhas}>
                        <Route index element={<LinhasPage />} />
                        <Route path={IMenuLinks.LinhasProdutos}>
                            <Route index element={<LinhaPage />} />
                            <Route path={IMenuLinks.Produto} element={<ProdutoPage />} />
                        </Route>
                    </Route>
                    <Route path={IMenuLinks.Video} element={<VideoInstitucionalPage />} />
                    <Route path={IMenuLinks.Feira} element={<ModoFeiraPage />} />
                </Route>
            </Routes>
        </DataProvider>
    )
}

export default AppRoutes