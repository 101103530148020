import React, { useEffect, useState } from "react"

import './ModoFeiraPage.scss'
import { IMG_Info } from "../../assets/img"
import { useData } from "../../contexts/DataContext"
import { Produto } from "../../core/classes"
import Header from "../../components/Header"
import VideoPlayer from "../../components/VideoPlayer"

interface IPageProps {
}

const ModoFeiraPage: React.FunctionComponent<IPageProps> = () => {
    const { getModoFeira } = useData()
    const [ produtos, setProdutos ] = useState<Array<Produto>>([])
    const [ index, setIndex ] = useState<number | null>()
    const [ produto, setProduto ] = useState<Produto | null>()

    useEffect(() => {
        getModoFeira().then((produtos) => {
            // console.log('Carregando os produtos', produtos)
            setProdutos(produtos)
        })
    }, [])

    useEffect(() => {
        if (produtos.length) {
            setIndex(0)
        }
    }, [produtos])

    useEffect(() => {
        console.log(index)
        if (index !== undefined) {
            console.log('Setando produto', produtos[(index || 0)])
            setProduto(produtos[(index || 0)])
        }
    }, [index])

    useEffect(() => {
        console.log('Produto', produto)
    }, [produto]);

    const nextProduto = () => {
        produtos[(index || 0) + 1] !== undefined ? setIndex((index || 0) + 1) : setIndex(0)
    }

    return (
        <div className="ModoFeiraPage">
            <Header title={"Modo Feira - " + (produto?.titulo || 'Carregando')}/>
            <div className="video-box">
                { produto && (
                    <VideoPlayer source={produto?.video_url} onEnd={nextProduto} autoplay/>
                )}
            </div>
        </div>
    )
}

export default ModoFeiraPage