import React, { useEffect, useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router"

import { useData } from "../../contexts/DataContext"

import Header from "../../components/Header"
import VideoPlayer from "../../components/VideoPlayer"

import { IMenuLinks } from "../../core/enums"
import { Utils } from "../../core/utils"
import { Linha, Produto } from "../../core/classes"

import './ProdutoPage.scss'
import LinksBar from "../../components/LinksBar"

interface IPageProps {
}

const ProdutoPage: React.FunctionComponent<IPageProps> = () => {
    const [produto, setProduto] = useState<Produto | null>(null)
    const [linha, setLinha] = useState<Linha | null>(null)

    const {getLinhaBySlug} = useData()

    const navigate: NavigateFunction = useNavigate()
    const params = useParams()

    useEffect(() => {
        if (params?.linha) {
            getLinhaBySlug(params.linha).then((linha) => {
                setLinha(linha)
                let produto = params.slug ? linha.getProdutoBySlug(params.slug) : null

                produto ? setProduto(produto) : onBack()
            }).catch((error) => {
                onBack()
            })
        } else {
            onBack()
        }
    }, [])

    const onBack = () => {
        linha ? navigate({
            pathname: Utils.parseRouteParam(IMenuLinks.LinhasProdutos, 'linha', linha.slug())
        }) : navigate({
            pathname: IMenuLinks.Linhas
        })
    }

    return (
        <div className="ProdutoPage">
            <Header title={produto?.titulo || ''} onBack={onBack}/>
            <div className="video-box">
                { produto && (
                    <VideoPlayer source={produto?.video_url} />
                )}
            </div>
            <LinksBar links={produto?.links || []}/>
        </div>
    )
}

export default ProdutoPage