import React, { useEffect, useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router"

import { useData } from "../../contexts/DataContext"

import Card from "../../components/Card"
import Header from "../../components/Header"

import { Linha, Produto } from "../../core/classes"
import { IMenuLinks } from "../../core/enums"
import { Utils } from "../../core/utils"

import './LinhaPage.scss'

interface IPageProps {
}

const LinhaPage: React.FunctionComponent<IPageProps> = () => {
    const [linha, setLinha] = useState<Linha | null>(null)

    const {getLinhaBySlug} = useData()

    const navigate: NavigateFunction = useNavigate()
    const params = useParams()

    useEffect(() => {
        if (params.linha) {
            getLinhaBySlug(params.linha).then((linha) => {
                setLinha(linha)
            }).catch((error) => {
                navigate({
                    pathname: IMenuLinks.Linhas
                })
            })
        } else {
            navigate({
                pathname: IMenuLinks.Linhas
            })
        }
    }, [])

    const onBack = () => {
        navigate({
            pathname: IMenuLinks.Linhas
        })
    }

    const openProduto = (produto: Produto) => {
        let route: string = IMenuLinks.Produto
        route = Utils.parseRouteParam(route, 'linha', linha?.slug() || '')
        route = Utils.parseRouteParam(route, 'slug', produto?.slug() || '')

        navigate({
            pathname: route,
        })
    }

    const renderProdutos = () => {
        if (linha?.produtos?.length) {
            return linha.produtos.map((produto, i) => {
                return (
                    <Card key={i} title={produto.titulo} image={produto?.video_url} isProduct={true} onClick={(event) => { openProduto(produto) }}/>
                )
            })
        } else {
            return null
        }
    }

    return (
        <div className="LinhaPage">
            { linha && (
                <>
                    <Header title={linha?.titulo + ' - Produtos'} onBack={onBack}/>
                    <div className="common-list">
                        { renderProdutos() }
                    </div>
                </>
            )}
        </div>
    )
}

export default LinhaPage