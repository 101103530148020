import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faCartShopping, faCompress, faExpand, faPause, faPlay, faSyncAlt, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faPlay, faArrowRight, faPause, faVolumeUp, faVolumeMute, faSyncAlt, faCompress, faExpand, faCartShopping)
library.add(faInstagram, faFacebook, faTwitter)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
