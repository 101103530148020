import React, { useEffect, useState } from "react"

import { useData } from "../../contexts/DataContext"

import Header from "../../components/Header"
import VideoPlayer from "../../components/VideoPlayer"

import { IVideo } from "../../core/interfaces/data"

import './VideoInstitucionalPage.scss'

interface IPageProps {
}

const VideoInstitucionalPage: React.FunctionComponent<IPageProps> = () => {
    const [video, setVideo] = useState<IVideo | null>(null);

    const {getVideoInstitucional} = useData();

    useEffect(() => {
        getVideoInstitucional().then((video) => {
            setVideo(video)
        })
    }, [])

    return (
        <div className="VideoInstitucionalPage">
            <Header title={video?.titulo || ''}/>
            <div className="video-box">
                { video && (
                    <VideoPlayer source={video?.url} />
                )}
            </div>
        </div>
    )
}

export default VideoInstitucionalPage