import React from 'react'

import './LinksBar.scss'
import { ILinkProduto } from '../../core/interfaces/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type IComponentProps = {
    links: Array<ILinkProduto>,
}

const LinksBar: React.FC<IComponentProps> = ({ links }) => {
    const renderLinks = () => {
        return links?.map((link, index) => (
            <a key={index} className="link-button" href={link.url} target="_blank" rel="noreferrer">
                <div className="icon">
                    <FontAwesomeIcon icon={link.icon} size="2x"></FontAwesomeIcon>
                </div>
            </a>
        ))
    }

    return (
        <>
            { links.length > 0? (
                <nav className="LinksBar">
                    {renderLinks()}
                </nav>
            ) : null}
        </>
    )
}

export default LinksBar