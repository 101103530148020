import React from "react"

import CustomLink from "./CustomLink"

import './MenuSidebar.scss'
import { IMG_Home, IMG_Web, IMG_Presentation, IMG_Video, IMG_Logo, IMG_ProductCategory } from "./../../assets/img/index"

import { IMenuLinks } from "./../../core/enums"

type IComponentProps = {
}

const MenuSidebar: React.FC<IComponentProps> = (): JSX.Element => {
    return (
        <aside className="MenuSidebar">
            <ul>
                <li>
                    <CustomLink to={IMenuLinks.Home}>
                        <div className="icon">
                            <img src={IMG_Home} alt="Home" />
                        </div>
                        <div className="label">Home</div>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to={IMenuLinks.Linhas}>
                        <div className="icon">
                            <img src={IMG_ProductCategory} alt="Linhas" />
                        </div>
                        <div className="label">Linhas</div>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to={IMenuLinks.Video}>
                        <div className="icon">
                            <img src={IMG_Video} alt="Vídeo Institucional" />
                        </div>
                        <div className="label">Vídeo Institucional</div>
                    </CustomLink>
                </li>
                <li>
                    <CustomLink to={IMenuLinks.Feira} >
                        <div className="icon">
                            <img src={IMG_Presentation} alt="Modo Feira" />
                        </div>
                        <div className="label">Modo Feira</div>
                    </CustomLink>
                </li>
                <li>
                    <a href="https://www.google.com/" rel="noreferrer" target="_blank">
                        <div className="icon">
                            <img src={IMG_Web} alt="Website" />
                        </div>
                        <div className="label">Website</div>
                    </a>
                </li>
            </ul>
            <div className="logo">
                <img src={IMG_Logo} alt="Vídeo Catálogo" />
            </div>
        </aside>
    )
}

export default MenuSidebar