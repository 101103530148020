import React from "react"
import { Outlet } from "react-router-dom"

import ContentLayout from "../ContentLayout"

import WindowBar from '../../components/WindowBar'
import MenuSidebar from "../../components/MenuSidebar"

import './MainLayout.scss'

type IComponentProps = {
}

const MainLayout: React.FC<IComponentProps> = ({
    children
}): JSX.Element => {
    
    return (
        <main className="MainLayout">
            { false && (
                <WindowBar></WindowBar>
            )}
            <div className="main-content">
                <MenuSidebar />
                <ContentLayout>
                    <Outlet />
                </ContentLayout>
            </div>
        </main>
    )
}

export default MainLayout