import React, { createContext, useContext, useState } from "react";
import { IMG_Linha1, IMG_Linha2, IMG_Linha3 } from "../assets/img";
import { Linha, Produto } from "../core/classes";
import { IVideo } from "../core/interfaces/data";
import { IconName } from "@fortawesome/fontawesome-svg-core";

const data = [
    {
        titulo: 'Televisores',
        image_url: IMG_Linha1,
        slug: 'televisores',
        produtos: [
            {
                titulo: 'Smart TV',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/6PmxmZGLSJIBkLifVhPi2o/db798b3d1f34cff13219cdb0ca559bfe/video.mp4',
                links: [
                    {
                        url: 'https://www.olipaz.com.br/programa-oli-paz',
                        icon: "fa-cart-shopping" as IconName
                    },
                    {
                        url: 'https://www.facebook.com',
                        icon: 'fa-brands fa-facebook' as IconName
                    }
                ]
            },
            {
                titulo: 'LG 98UH9800',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/55lAyO5iWoPxXH3R0egQCt/4852b5113bcee4745fb41539834150a5/video.mp4',
                links: [
                    {
                        url: 'https://www.olipaz.com.br/terroir-brasileiro',
                        icon: 'fa-cart-shopping' as IconName
                    },
                    {
                        url: 'https://www.instagram.com',
                        icon: 'fa-brands fa-instagram' as IconName
                    }
                ]
            },
            {
                titulo: 'Função Futebol',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/3NofdADy83v0JQXzZT2GR4/879db0990983cc391c18fd817bfb5fe3/video.mp4',
                links: [
                    {
                        url: 'https://www.olipaz.com.br/jogo-do-poder',
                        icon: 'fa-cart-shopping' as IconName
                    },
                    {
                        url: 'https://www.twitter.com',
                        icon: 'fa-brands fa-twitter' as IconName
                    }
                ]
            },
            {
                titulo: 'Dicas Smart TVs',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/5J2Pou3SAHSORbl94lcgIV/8d35280eeaf1b28048040a00e3b06bce/video.mp4',
                links: [
                    {
                        url: 'https://www.olipaz.com.br/confraria',
                        icon: 'fa-cart-shopping' as IconName
                    },
                    {
                        url: 'https://www.twitter.com',
                        icon: 'fa-brands fa-twitter' as IconName
                    }
                ]
            }
        ]
    },
    {
        titulo: 'Vestidos / Malhas',
        image_url: IMG_Linha2,
        slug: 'vestidos-malhas',
        produtos: [
            {
                titulo: 'Como são feitos',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/q0pced2C37bxOVzI95iGD/c464c5175690accaeb246c29243f14e4/video.mp4',
                links: []
            },
            {
                titulo: 'Malhas Jadifex',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/49DV2FOd9ZME8mbkZUbzqT/70db948b9877e6d2ac78ef3d648937d0/video.mp4',
                links: []
            },
            {
                titulo: 'Biamar Outono/Inverno',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/3Eh6RPYheRh7FECHrftNsf/b996209731d237fc8cf78f4a2fa5e4e6/video.mp4',
                links: []
            }
        ]
    },
    {
        titulo: 'Panelas',
        image_url: IMG_Linha3,
        slug: 'panelas',
        produtos: [
            {
                titulo: 'Viva Digital Philips Walita',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/53gTxR4arkIcAZmYpWOQm3/09fe275cc82939e7036b947e2dbf9d22/video.mp4',
                links: []
            },
            {
                titulo: 'Flavorstone',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/5QCrQNhdN9s8qQbeF4kVej/3c38c5a5c247935080c7a0c4503abbbc/video.mp4',
                links: []
            },
            {
                titulo: 'Antiaderente ou Teflon',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/2hWwSm3DPQGEHGL6q7PWgO/dff3eb70bf9dcc4eac14e7c3392ac95a/video.mp4',
                links: []
            },
            {
                titulo: 'Panelas iCook',
                video_url: '//videos.ctfassets.net/usu13rnbwxko/7rLr2rOiQSy8iyLAi12Qyg/bd5cee0a246012bc734c86f0b2815c93/video.mp4',
                links: []
            }
        ]
    }
];

interface IDataContext {
    getVideoInstitucional(): Promise<IVideo>
    getLinhas(): Promise<Array<Linha>>
    getModoFeira(): Promise<Array<Produto>>
    getLinhaBySlug(slug: string): Promise<Linha>
    produto: Produto | null
    setProduto: React.Dispatch<React.SetStateAction<Produto | null>>
    linha: Linha | null
    setLinha: React.Dispatch<React.SetStateAction<Linha | null>>
}

const DataContext = createContext<IDataContext>({} as IDataContext)

export const DataProvider: React.FC = ({children}) => {
    const [produto, setProduto] = useState<Produto | null>(null)
    const [linha, setLinha] = useState<Linha | null>(null)

    function getLinhas(): Promise<Array<Linha>> {
        return new Promise((resolve, reject) => {
            let linhas = data.map((original_linha) => {
                return new Linha(
                    original_linha.titulo, 
                    original_linha.image_url, 
                    original_linha.produtos.map((original_produto) => {
                        return new Produto(original_produto.titulo, original_produto.video_url, original_produto.links)
                    })
                )
            })

            resolve(linhas)
        })
    }

    function getLinhaBySlug(slug: string): Promise<Linha> {
        return new Promise((resolve, reject) => {
            getLinhas().then((linhas) => {
                let linha: Linha | undefined = linhas.find((search_linha) => search_linha.slug() === slug)

                linha ? resolve(linha) : reject()
            }).catch((error) => {
                reject(error)
            })
        })
    }

    function getVideoInstitucional(): Promise<IVideo> {
        return new Promise((resolve, reject) => {
            resolve({
                "titulo": 'Vídeo Institucional',
                "url": 'https://player.vimeo.com/external/651441825.hd.mp4?s=0e187e82f0037e64dd4d8cc1b1f59b07e510953d&profile_id=174'
            })
        })
    }

    function getModoFeira(): Promise<Array<Produto>> {
        return new Promise((resolve, reject) => {
            getLinhas().then((linhas) => {
                let produtos = linhas.reduce<Array<Produto>>((array_produtos: Array<Produto>, linha) => {
                    return array_produtos.concat(linha.produtos)
                }, [])

                resolve(produtos)
            }).catch((error) => {
                console.log(error)

                reject(error)
            })
        })
    }

    return (
        <DataContext.Provider
            value={{
                getVideoInstitucional,
                getLinhas,
                getLinhaBySlug,
                produto,
                setProduto,
                linha,
                setLinha,
                getModoFeira
            }}>
                {children}
        </DataContext.Provider>
    )
}

export function useData() {
    const context = useContext(DataContext)
    return context
}