import React from "react"

import './ContentLayout.scss'

type IComponentProps = {
}

const ContentLayout: React.FC<IComponentProps> = ({
    children
}): JSX.Element => {
    
    return (
        <div className="ContentLayout">
            {children}
        </div>
    )
}

export default ContentLayout