import { ILinha, ILinkProduto, IProduto } from "./interfaces/data";
import { Utils } from "./utils";

export class Produto implements IProduto {
    titulo: string
    video_url: string
    links: ILinkProduto[]
    
    constructor(titulo: string, video_url: string, links: ILinkProduto[] = []) {
        this.titulo = titulo
        this.video_url = video_url
        this.links = links
    }

    slug(): string {
        return Utils.slugify(this.titulo)
    }
}

export class Linha implements ILinha {
    titulo: string
    image_url: string
    produtos: Array<Produto>
    
    constructor(titulo: string, image_url: string, produtos?: Array<Produto>) {
        this.titulo = titulo
        this.image_url = image_url
        this.produtos = produtos || []
    }

    getProdutoBySlug(slug: string): Produto | undefined {
        return this.produtos.find((produto) => produto.slug() === slug);
    }

    slug(): string {
        return Utils.slugify(this.titulo)
    }
}