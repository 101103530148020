import React, { useEffect, useRef, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Utils } from "../../core/utils"

import './VideoPlayer.scss'

type IComponentProps = {
    source: string
    autoplay?: boolean
    controls?: boolean
    corners?: boolean
    muted?: boolean
    controlsList?: string
    onEnd?: Function
    onPlaying?: Function
    onGoFull?: Function
    onExitFull?: Function
}

const VideoPlayer: React.FC<IComponentProps> = ({
    source = '',
    autoplay = false,
    controls = true,
    corners = false,
    muted = false,
    controlsList = '',
    onEnd = () => {},
    onPlaying = () => {},
    onGoFull = () => {},
    onExitFull = () => {},
}): JSX.Element => {
    // @Input('source') source: string = ''
    // @Input('autoplay') autoplay: boolean = false
    // @Input('controls') controls: boolean = true
    // @Input('corners') corners: boolean = false
    // @Input('controlsList') controlsList: string = ''

    // @Output('onEnd') onEnd: EventEmitter<any> = new EventEmitter<any>()
    // @Output('onPlaying') onPlaying: EventEmitter<any> = new EventEmitter<any>()
    // @Output('onGoFull') onGoFull: EventEmitter<any> = new EventEmitter<any>()
    // @Output('onExitFull') onExitFull: EventEmitter<any> = new EventEmitter<any>()

    // @ViewChild('media') media: ElementRef<HTMLVideoElement>
    // @ViewChild('video') videoRef: ElementRef<HTMLVideoElement>

    const media = useRef<HTMLVideoElement>(null)
    const videoRef = useRef<HTMLDivElement>(null)

    // config = CONFIG
    // caminhoAssets = this.config.CAMINHO_PAGES_ASSETS

    const [repetir, setRepetir] = useState<boolean>(false)
    const [carregando, setCarregando] = useState<boolean>(true)
    const [documentRef, setDocumentRef] = useState<any>(window.document)
    const [currentTime, setCurrentTime] = useState<number>(0)
    const [step, setStep] = useState<number>(0)
    const [duration, setDuration] = useState<number>(0)
    const [progress, setProgress] = useState<number>(0)
    const [volume, setVolume] = useState<number>(0) 
    const [volumeAtual, setVolumeAtual] = useState<number>(1)

    const [mouseOver, setMouseOver] = useState<boolean>(false)
    const [movimentandoMouse, setMovimentandoMouse] = useState<boolean>(true)
    const [timer, setTimer] = useState<any>(null)

    
    useEffect(() => {
        if (videoRef?.current) {
            videoRef.current.addEventListener("webkitfullscreenchange", (event: any) => {
                if (documentRef['webkitFullscreenElement'] && event && event.target['id'] == "video") {
                    //Enter fullscreen
                    onGoFull()
                } else if (!documentRef['webkitFullscreenElement'] && event && event.target['id'] == "video") {
                    //Leave fullscreen
                    onExitFull()
                }
            })
        }

        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        setStep(duration / 100)
    }, [duration])

    useEffect(() => {
        setProgress(currentTime / step)
    }, [currentTime, step])

    // TODO: Refazer lógica para renovar o timer e manter os controles habilitados
    useEffect(() => {
        if (movimentandoMouse) {
            setTimer(renewTimer)
        }
    }, [movimentandoMouse])

    useEffect(() => {
        if (media?.current) {
            media.current.load()
        }
    }, [source])

    // useEffect(() => {
    //     changeVolume(volume)
    // }, [volume])

    // useEffect(() => {
    //     changeToTime(currentTime)
    // }, [currentTime])

    const mostrarControles = (event: any) => {
        setMovimentandoMouse((prev) => true)
    }

    const renewTimer = (prevTimer: any) => {
        if (prevTimer) {
            clearTimeout(prevTimer)
        }

        let newTimer = setTimeout(() => {
            setMovimentandoMouse(false)
        }, 4000)

        return newTimer
    }

    const togglePlay = () => {
        if (media?.current?.paused) {
            media.current.play()
        } else if (media?.current) {
            media.current.pause()
        }
    }

    const toggleRepetir = () => {
        setRepetir(!repetir)
    }

    const toggleFullScreen = () => {
        if (documentRef['webkitFullscreenElement'] && documentRef['webkitFullscreenElement'].id == "video") {
            documentRef['webkitExitFullscreen']()
        } else if (videoRef?.current) {
            let videoRefAny: any = videoRef.current
            videoRefAny['webkitRequestFullscreen']()
        }
    }

    const toggleVolume = () => {
        if (media?.current) {
            if (media.current.volume > 0) {
                setVolumeAtual(media.current.volume)
                media.current.volume = 0
            } else {
                media.current.volume = volumeAtual
            }
        }
    }

    // Video Events
    /* onend */
    const ended = (event: any) => {
        if (!repetir) {
            onEnd(event)
        } else if (media?.current) {
            media.current.play()
        }
    }

    /* timeupdate */
    const timeChange = (event: any) => {
        if (event?.target) {
            setCurrentTime(event?.target?.currentTime)

            if (event?.target?.duration) {
                setDuration(event.target.duration)
            }
        }
    }

    /* volumechange */
    const volumeChange = (event: any) => {
        if (event?.target) {
            setVolume(event?.target?.volume)
        }
    }

    /* loading */
    const loadingVideo = () => {
        setCarregando(true)
    }

    /* seeking */
    const seekingVideo = () => {
        setCarregando(true)
    }

    /* playing */
    const playingVideo = () => {
        setCarregando(false)
        onPlaying()
    }

    /* seeked */
    const seekedVideo = () => {
        setCarregando(false)
    }

    /* loadeddata */
    const loadedVideo = () => {
        setCarregando(false)

        if (autoplay && media?.current) {
            media.current.play();
        }
    }

    const changeToVolume = (event: any) => {
        if (event?.target && media?.current) {
            media.current.volume = event?.target?.value
        }
    }

    const changeToTime = (event: any) => {
        if (event?.target && media?.current) {
            media.current.currentTime = event?.target?.value
        }
    }



    return (
        <div className={`video-container ${corners ? 'radius' : ""}`} id="video" ref={videoRef} onPointerMove={mostrarControles} style={{cursor: movimentandoMouse ? 'unset' : 'none'}}>
            <video 
                ref={media}
                autoPlay={autoplay} 
                controls={false} 
                controlsList="controlsList" 
                muted={muted}
                onEnded={ended}
                onLoadStart={loadingVideo}
                onPlaying={playingVideo}
                onSeeking={seekingVideo}
                onSeeked={seekedVideo}
                onLoadedData={loadedVideo}
                onTimeUpdate={timeChange}
                onVolumeChange={volumeChange}
                preload="auto">
                <source src={source} />
            </video>
            { carregando && (
                <div className="sk-circle">
                    <div className="sk-circle1 sk-child"></div>
                    <div className="sk-circle2 sk-child"></div>
                    <div className="sk-circle3 sk-child"></div>
                    <div className="sk-circle4 sk-child"></div>
                    <div className="sk-circle5 sk-child"></div>
                    <div className="sk-circle6 sk-child"></div>
                    <div className="sk-circle7 sk-child"></div>
                    <div className="sk-circle8 sk-child"></div>
                    <div className="sk-circle9 sk-child"></div>
                    <div className="sk-circle10 sk-child"></div>
                    <div className="sk-circle11 sk-child"></div>
                    <div className="sk-circle12 sk-child"></div>
                </div>
            )}
            
            {(controls && movimentandoMouse && !carregando) && (
                <div className="controls-big-play" onClick={togglePlay}>
                    {(media?.current && media.current.paused) && (
                        <FontAwesomeIcon icon="play"  size="sm"/>
                    )}
                </div>
            )}

            {(controls && movimentandoMouse && !carregando && documentRef['webkitFullscreenElement']) && (
                <button className="controls-back rounded-button" onClick={toggleFullScreen}>Sair do Vídeo
                <FontAwesomeIcon icon="arrow-right" size="sm"/></button>
            )}
            
            {(controls && movimentandoMouse && source) && (
                <div className="controls-container">
                    <div className="control-button tooltip" onClick={togglePlay}>
                        { media?.current && media.current.paused ? (
                            <>
                                <FontAwesomeIcon icon="play"  size="sm"/>
                                <span className="tooltiptext tooltip-top">Play</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon="pause"  size="sm"/>
                                <span className="tooltiptext tooltip-top">Pause</span>
                            </>
                        )}
                    </div>

                    <div className="control-button tooltip" onClick={toggleVolume}>
                        { media?.current && media.current.volume > 0 ? (
                            <>
                                <FontAwesomeIcon icon="volume-up"  size="sm"/>
                                <span className="tooltiptext tooltip-top">Mudo</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon="volume-mute"  size="sm"/>
                                <span className="tooltiptext tooltip-top">Volume</span>
                            </>
                        )}
                    </div>
                    
                    <div className="slider-bar volume">
                        {/* [(ngModel)]="media.volume" */}
                        <input className="slider" id="volumeslider" type="range" onChange={changeToVolume} min="0" max="1" step="0.01" />

                        <svg className="bar">
                            <rect rx="4px" ry="4px" alignmentBaseline="middle" className="background"/>
                            <rect rx="4px" ry="4px" alignmentBaseline="middle" width={(media?.current?.volume || 0) * 100 + '%'} className="actual"/>
                        </svg>
                    </div>

                    <div className="control-text">
                        {Utils.formatTime(currentTime)}
                    </div>

                    <div className="slider-bar progress">
                        {/* [(ngModel)]="media.currentTime" */}
                        <input className="slider" id="progressslider" type="range" onChange={changeToTime} value={currentTime} min="0" max={media?.current?.duration || 0} />
                        <svg className="bar">
                            <rect rx="4px" ry="4px" alignmentBaseline="middle" className="background"/>
                            <rect rx="4px" ry="4px" alignmentBaseline="middle" width={(progress || 0) + '%'} className="actual"/>
                        </svg>
                    </div>

                    {/* 
                        <svg className="volume-bar" id="volumebar" onClick={changeVolume}vent, volumebar)">
                        <rect rx="3px" ry="3px" alignmentBaseline="middle" className="volume-background"/>
                        <rect rx="3px" ry="3px" alignmentBaseline="middle" [attr.width]="volume+'%'" className="volume-actual"/>
                        </svg>
                    */}
                    
                    {/* 
                        <svg className="progress-bar" #progressbar onClick={changeCurrentTime}vent, progressbar)">
                        <rect rx="3px" ry="3px" alignmentBaseline="middle" className="progress-background"/>
                        <rect rx="3px" ry="3px" alignmentBaseline="middle" [attr.width]="progress+'%'" className="progress-actual"/>
                        <circle [attr.cx]="progress+'%'" cy="50%" className="progress-indicator"/>
                        </svg>
                    */}
                    
                    <div className="control-text">
                        {Utils.formatTime(duration)}
                    </div>
                    
                    <div className="control-button tooltip" onClick={toggleRepetir}>
                        { repetir ? (
                            <>
                                <FontAwesomeIcon icon="sync-alt" style={{ color: '#fff' }} size="sm"/>
                                <span className="tooltiptext tooltip-top">Repetindo</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon="sync-alt" style={{ color: 'unset' }} size="sm"/>
                                <span className="tooltiptext tooltip-top">Não Repetir</span>
                            </>
                        )}
                    </div>

                    <div className="control-button tooltip" onClick={toggleFullScreen}>
                        { documentRef['webkitFullscreenElement'] ? (
                            <>
                                <FontAwesomeIcon icon="compress" style={{ color: '#fff' }} size="sm"/>
                                <span className="tooltiptext tooltip-top">Sair</span>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon="expand" style={{ color: 'unset' }} size="sm"/>
                                <span className="tooltiptext tooltip-top">Tela cheia</span>
                            </>
                        )}
                    </div>
                </div>
            )}

            { (controls && movimentandoMouse && source) && (
                <div className="controls-container mobile">
                    <div>
                        <div className="control-text">
                                {Utils.formatTime(currentTime)}
                        </div>

                        <div className="slider-bar progress">
                            {/* [(ngModel)]="media.currentTime" */}
                            <input className="slider" id="progressslider" type="range" onChange={changeToTime} value={currentTime} min="0" max={media?.current?.duration || 0} />
                            <svg className="bar">
                                <rect rx="4px" ry="4px" alignmentBaseline="middle" className="background"/>
                                <rect rx="4px" ry="4px" alignmentBaseline="middle" width={(progress || 0) + '%'} className="actual"/>
                            </svg>
                        </div>
                        <div className="control-text">
                            {Utils.formatTime(duration)}
                        </div>
                    </div>
                    <div>
                        <div className="control-button tooltip" onClick={togglePlay}>
                            { media?.current?.paused ? (
                                <>
                                    <FontAwesomeIcon icon="play"  size="sm"/>
                                    <span className="tooltiptext tooltip-top">Play</span>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon="pause"  size="sm"/>
                                    <span className="tooltiptext tooltip-top">Pause</span>
                                </>
                            )}
                        </div>

                        <div className="control-button tooltip" onClick={toggleVolume}>
                            { (media?.current?.volume || 0) > 0 ? (
                                <>
                                    <FontAwesomeIcon icon="volume-up"  size="sm"/>
                                    <span className="tooltiptext tooltip-top">Mudo</span>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon="volume-mute"  size="sm"/>
                                    <span className="tooltiptext tooltip-top">Volume</span>
                                </>
                            )}
                        </div>

                        <div className="slider-bar volume">
                            {/* [(ngModel)]="media.volume" */}
                            <input className="slider" id="volumeslider" type="range" onChange={changeToVolume} min="0" max="1" step="0.01" />
                            <svg className="bar">
                                <rect rx="4px" ry="4px" alignmentBaseline="middle" className="background"/>
                                <rect rx="4px" ry="4px" alignmentBaseline="middle" width={(media?.current?.volume || 0) * 100 + '%'} className="actual"/>
                            </svg>
                        </div>
                        <div className="control-button tooltip" onClick={toggleRepetir}>
                            { repetir ? (
                                <>
                                    <FontAwesomeIcon icon="sync-alt" style={{ color: '#fff' }} size="sm"/>
                                    <span className="tooltiptext tooltip-top">Repetindo</span>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon="sync-alt" style={{ color: 'unset' }} size="sm"/>
                                    <span className="tooltiptext tooltip-top">Não Repetir</span>
                                </>
                            )}
                        </div>
                        <div className="control-button tooltip" onClick={toggleFullScreen}>
                            { documentRef['webkitFullscreenElement'] ? (
                                <>
                                    <FontAwesomeIcon icon="compress" style={{ color: '#fff' }} size="sm"/>
                                    <span className="tooltiptext tooltip-top">Sair</span>
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon="expand" style={{ color: 'unset' }} size="sm"/>
                                    <span className="tooltiptext tooltip-top">Tela cheia</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default VideoPlayer