import React, { useEffect, useState } from "react"
import { NavigateFunction, useNavigate } from "react-router"

import { useData } from "../../contexts/DataContext"

import Card from "../../components/Card"
import Header from "../../components/Header"

import { Linha } from "../../core/classes"
import { IMenuLinks } from "../../core/enums"
import { Utils } from "../../core/utils"

import './LinhasPage.scss'

interface IPageProps {
}

const LinhasPage: React.FunctionComponent<IPageProps> = () => {
    const {getLinhas} = useData()

    const [linhas, setLinhas] = useState<Array<Linha>>([])
    
    const navigate: NavigateFunction = useNavigate()

    useEffect(() => {
        getLinhas().then((linhas) => {
            setLinhas(linhas)
        })
    }, [])

    const openLinha = (linha: Linha) => {
        let route: string = IMenuLinks.LinhasProdutos
            route = Utils.parseRouteParam(route, 'linha', linha?.slug() || '')
    
        navigate({
            pathname: route,
        })
    }

    const renderLinhas = () => {
        return linhas.map((linha, i) => {
            return (
                <Card key={i} title={linha.titulo} image={linha.image_url} onClick={(event) => { openLinha(linha) }}/>
            )
        })
    }

    return (
        <div className="LinhasPage">
            <Header title="Linhas" />
            <div className="common-list">
                { renderLinhas() }
            </div>
        </div>
    )
}

export default LinhasPage