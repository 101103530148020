import React from "react"

import Header from "../../components/Header"
import CustomLink from "../../components/MenuSidebar/CustomLink"

import './HomePage.scss'
import { IMG_Background, IMG_Presentation, IMG_ProductCategory, IMG_Video, IMG_Web } from "../../assets/img"

import { IMenuLinks } from "../../core/enums"

interface IPageProps {
}

const HomePage: React.FunctionComponent<IPageProps> = () => {
    return (
        <div className="HomePage">
            <div className="content">
                <Header home/>
                
                <section className="common-limiter title">
                    <hr />
                    <h1>Vídeo Catalogo Digital</h1>
                    <h2>Nome da Empresa</h2>
                    <button className="outline-button">Veja Mais</button>
                    <div className="menu-mobile">
                        <ul>
                            <li className="outline-button">
                                <CustomLink to={IMenuLinks.Linhas}>
                                    <div className="icon">
                                        <img src={IMG_ProductCategory} alt="Linhas" />
                                    </div>
                                    <div className="label">Linhas</div>
                                </CustomLink>
                            </li>
                            <li className="outline-button">
                                <CustomLink to={IMenuLinks.Video}>
                                    <div className="icon">
                                        <img src={IMG_Video} alt="Vídeo Institucional" />
                                    </div>
                                    <div className="label">Vídeo Institucional</div>
                                </CustomLink>
                            </li>
                            <li className="outline-button">
                                <CustomLink to={IMenuLinks.Feira} >
                                    <div className="icon">
                                        <img src={IMG_Presentation} alt="Modo Feira" />
                                    </div>
                                    <div className="label">Modo Feira</div>
                                </CustomLink>
                            </li>
                            <li className="outline-button">
                                <a href="https://www.google.com/" rel="noreferrer" target="_blank">
                                    <div className="icon">
                                        <img src={IMG_Web} alt="Website" />
                                    </div>
                                    <div className="label">Website</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="common-limiter contact">
                    <div>
                        <a href="mailto:videocatalogo@olipaz.com.br">videocatalogo@olipaz.com.br</a>
                    </div>
                    <div>
                        <a href="tel:5554991356368">(54) 99135-6368</a>
                    </div>
                </section>
            </div>
            <div className="background">
                <img src={IMG_Background} alt="Background" />
            </div>
        </div>
    )
}

export default HomePage